import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { SurveySearchFilter,SurveyWriteHeader, SurveyDetailHeader, SurveyReadQuestion, SurveyReadExamples, SurveyAttachmentList } from '@survey/components'
import api from '@survey/api'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as types from '@survey/store/types'
import * as dateUtil from '@appbase/util/date-helper'
import * as accountType from '@account/store/types'

//lazyLoading
const SurveyStatisticsUserSearchList = ()=> import('@survey/components/SurveyRead/SurveyStatisticsUserSearchList.vue')
 

@Component({
    components: {
        SurveySearchFilter,
        SurveyDetailHeader,
        SurveyAttachmentList,
        SurveyReadQuestion,
        SurveyReadExamples,
        SurveyWriteHeader,
        SurveyStatisticsUserSearchList
    },
    methods: {
        ...mapActions({
            addAnswer: types.SURVEY_ANSWER,
            setSurveyId: types.SURVEY_CREATED_SURVEYID
        }),
        ...mapMutations({
            initAnswer: types.SURVEY_ANSWER,
            setStatistics: types.SURVEY_STATISTICS,
            deleteAllAnswer: types.SURVEY_DELETE_ALL_ANSWER
        })
    },
    computed: {
        ...mapGetters({
            menuPermission: types.SURVEY_MENU_PERMISSIONS,
            answers: types.SURVEY_ANSWER,
            user : accountType.CURRENT

        })
    }
})
export default class SurveyReadView extends Vue {
    @Prop({ type: Boolean , default:false }) isPreview!: boolean  

    deleteAllAnswer!: () => void
    initAnswer!: (data: survey.SurveyAnswerItem) => void
    addAnswer!: (data: survey.SurveyAnswerItem) => void
    setStatistics!: (data: survey.SurveyDetailResponse) => void
    setSurveyId!: (surveyId?: number) => void

    answers!: Array<survey.SurveyAnswerItem>

    user!: account.TCurrent
    menuPermission!: survey.MenuPermissions

    isReady: boolean = false
    surveyDetail: survey.SurveyDetailResponse = Object.create(null)
    surveyAttachmentList: Array<survey.SurveyAttachmentInfo> = []
    isPopup : boolean = false
     

//사용자별통계 
    showSearch : boolean = false
    completionLists : Array<survey.SurveyCompletionItem> = []
    displayCompletionLists : Array<survey.SurveyCompletionItem> = []
    selectedTrNum : number = 0

    createdId : string = ""
     
    //이미 참여한 설문에서 수정하면 기존 store에 정보가 남아 있어
    //중복으로 설문이 진행되는 경우가 있음
    //이때 answer 빈값으로 초기화 
    destroyed() {
        this.deleteAllAnswer()
        
    }

    async created() {
        //미리보기인지 체크
        // this.isPreview = this.$route.query.isPreview == '1' ? : '2'

        //팝업인지 체크
        this.isPopup = this.$route.path.toLowerCase().includes('popup')

        this.setPage({
            title: this.isPreview ?   this.$t('설문작성').toString() :  this.$t('설문조사').toString()
        })

        try {
            //스토어를 안사용해도 될듯?
            let surveyId = parseInt(this.$route.query.surveyId as string)

            if (isNaN(surveyId)) {
                throw new Error("Invalid QueryString.(only numbers are allowed)")
            }

            api.survey.surveyDetail(surveyId).then(result => {

                if (result == null || !result.success) {
                    throw new Error("Failed to call api, beacuse response is null.")
                }
                //작성자아이디 기록
                

                //설문이 완료된 상태면 응답리스트 조회 시작
                if (result.surveyDetailInfo.isCompleted) {
                    api.survey.getSurveyAnswerLists(surveyId).then(answerListResult => {
                        //init  answers

                        answerListResult.forEach(answer => {
                            //중복답변인지 확인한다.
                            // answer.isMultipleAnswer
                            //store 에 push 할 것인지 지우고 푸가 할 것인지 결정하기 위함
                            var answerCount = answerListResult.filter(item => {
                                return item.surveyQuestionId == answer.surveyQuestionId
                            })

                            if (answerCount.length > 1) {
                                answer.isMultipleAnswer = true
                            }

                            this.initAnswer(answer)
                        })
                        //this.answers = answerListResult

                        this.surveyDetail = result
                        this.isReady = true

                    })
                } else {
                    this.surveyDetail = result
                    this.isReady = true
                }

                //첨부파일이 있는경우
                if (result.surveyDetailInfo.fileCode != "") {

                    api.survey.getSurveyAttachmentList(<string>result.surveyDetailInfo.fileCode)
                        .then(result => {


                            if (result.length > 0) {
                                this.surveyAttachmentList = result.map((item: any) => {
                                    return {
                                        downloadUrl: item.downloadUrl,
                                        fileName: item.name
                                    } as survey.SurveyAttachmentInfo
                                })
                            }
                        })
                }


            })
        } catch (err) {
            await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
            this.goBack()
            throw err
        }

    }

    goBack() {

        // 팝업일 때 액션 달라져야 함
         

        if (this.isPopup) {
            window.close()
        } 
        else if(this.isPreview){
            this.$router.go(-1)
        }
        else {
           this.goList()
        }
    }
    async goListPreview(){
        if(await(window as any).confirmAsync(this.$t('저장하지_않고_목록으로_이동하면_작성_중_상태가_유지_됩니다_').toString())){
            this.goList()
        }
    }

    async deleteHard() {
        const surveyId  = parseInt(<string>this.$route.query.surveyId)
        if (await(window as any).confirmAsync(this.$t('저장하지않고_취소').toString())) {

            api.survey.deleteHardSurvey(surveyId).then(async result => {
                if (result) {
                    this.setSurveyId()
                    this.goList()
                } else {
                    await (window as any).alertAsync(this.$t('설문_삭제를_실패').toString()  )
                }
            })
        }
    }

    async previewSave(){
        if (await(window as any).confirmAsync(this.$t('저장_하시겠습니까').toString())) {
            //유효성체크
            const surveyId  = parseInt(<string>this.$route.query.surveyId)
            api.survey.setSurveyComposingStatus({
                surveyId: surveyId,
                isComposing: false
            }).then(async result => {
                if (result) {
                    this.goList()
                } else {
                    await (window as any).alertAsync(this.$t('저장_실패').toString())
                }
            })
        }
    }

    goList(){
        this.$router.push({
            name: "SurveyList",
            query: {
                MENUID: this.$route.query.MENUID
            }
        })
    }

    async edit() {
        //체크액션이 아니라 관리자 아니면 안보이면 됨
        //수정 상태로 진입하면 리스트에 노출 안됨
        if (await(window as any).confirmAsync(this.$t('수정하시겠습니까').toString())) {
            api.survey.setSurveyComposingStatus({
                surveyId: parseInt(this.$route.query.surveyId as string),
                isComposing: true
            }).then(async result => {
                if (result) {
                    this.$router.push({
                        name: this.isPopup ? "SurveyPopupWriteStep01" : "SurveyWriteStep01",
                        query: {
                            surveyId: this.$route.query.surveyId,
                            MENUID: this.$route.query.MENUID
                        }
                    })
                } else {
                    await (window as any).alertAsync(this.$t('수정_실패').toString())
                }
            })

        }
    }

    updateAnswer(data: survey.SurveyAnswerItem) {
        //debugger
        data.surveyId = this.surveyDetail?.surveyDetailInfo.surveyId as number
        this.doAnswerFlag(data.surveyQuestionId)
        this.addAnswer(data)
    }
 
    doAnswerFlag(questionId: number) {

        this.surveyDetail?.questions.map(item => {
            if (item.questionInfo.surveyQuestionId == questionId) {
                item.questionInfo.doAnswer = true
            }
        })
    }

    checkAnswerAll() {
        //응답 수정 상태인 경우  예외처리 
        if (this.surveyDetail.surveyDetailInfo.isCompleted) {
            return true
        }



        //응답을 모두했는지 확인
        //doAnswerFlag 카운트 비교
        let entireQuestionCount = this.surveyDetail.questions.length

        let doAnswerCount = this.surveyDetail.questions.filter(item => {
            return item.questionInfo.doAnswer == true
        }).length


        if (entireQuestionCount == doAnswerCount) {
            return true
        } else {
            return false
        }
    }

    async save() {
        //설문응답 저장
        //모두 응답 했는지 확인을 해야됨.
        //true면 모두 응답함
        //수정이 아니고
        
        if (this.checkAnswerAll()) {
//수정인경우 위에 응답체크 시 예외처리해버림
//만약에 모든 응답을 제거 한 상태라면 정상 작동 할 수 있도록 임의 데이터 삽입
            let answerData : any = []
            if(this.answers.length == 0){
                answerData = [{
                    surveyId : parseInt(this.$route.query.surveyId as string)
                }]
            }else{
                answerData = this.answers
            }

            api.survey.insertSurveyAnswer({
                answers: answerData 
            }).then(async result => {
                if (result.success) {
                    await (window as any).alertAsync(this.$t('설문_응답_완료').toString())
                    this.goBack()
                } else {
                    await (window as any).alertAsync(this.$t('설문응답_저장_실패').toString())
                }
            })



            //응답 저장 후 callback 에 완료 flag api 콜
        } else {

            await (window as any).alertAsync(this.$t('모든_설문_응답').toString())
        }

    }


    getAnswerByid(questionId: number) {
        //완료대상이 아니면 [] 값 리턴
        if (!this.surveyDetail.surveyDetailInfo.isCompleted) {
            return []
        }


        let answer = this.answers.filter(item => {
            return item.surveyQuestionId == questionId
        })

        return answer
    }

    goStatistics() {

        this.setStatistics(this.surveyDetail) /// 설문정보(질문,보기)

        this.$router.push({

            name: this.isPopup ? "SurveyPopupStatistics" : "SurveyStatistics",
            query: {
                surveyId: this.surveyDetail.surveyDetailInfo.surveyId?.toString(),
                MENUID: this.$route.query.MENUID
            },



        })
    }

    async deleteSurvey() {

        if (await(window as any).confirmAsync(this.$t('삭제하시겠습니까').toString())) {

            api.survey.deleteSurvey(<number>this.surveyDetail.surveyDetailInfo.surveyId)
                .then(async result => {
                    if (result) {

                        await (window as any).alertAsync(this.$t('설문삭제_완료').toString())
                        this.goBack()
                    } else {

                        await (window as any).alertAsync(this.$t('설문_삭제를_실패').toString())
                    }
                })
        }
    }

    async nowPosting() {

        if (await(window as any).confirmAsync(this.$t('바로게시하시겠습니까').toString())) {

            api.survey.setSurveyNowPosting(<number>this.surveyDetail.surveyDetailInfo.surveyId)
                .then(async result => {
                    if (result) {

                        await (window as any).alertAsync(this.$t('게시완료').toString())
                        this.goBack()
                    } else {

                        await (window as any).alertAsync(this.$t('게시_실패').toString())
                    }
                })
        }
    }

    async nowClosing(){
        if (await(window as any).confirmAsync(this.$t('설문_즉시_종료').toString())) {

            api.survey.setSurveyNowClosing(<number>this.surveyDetail.surveyDetailInfo.surveyId)
                .then(async result => {
                    if (result) {

                        await (window as any).alertAsync(this.$t('설문종료').toString())
                        this.goBack()
                    } else {

                        await (window as any).alertAsync(this.$t('설문_종료가_실패').toString())
                    }
                })
        }
    }

    statisticsByUser(){
        this.showSearch = !this.showSearch

        

        const surveyId = <number>this.surveyDetail.surveyDetailInfo.surveyId
        
        //데이터 없을 때 api 콜
        if(this.showSearch && this.completionLists.length == 0 ){
            api.survey.getSurveyCompletionLists(surveyId).then(result =>{
                this.completionLists = result.lists
                this.displayCompletionLists = result.lists.map(item =>{
                    return {
                        ...item,
                        displayCreatedDate : this.convertDate(item.createdDate.toString())
                    }
                })

                const personCode = this.user.user.personCode
                
                const target = result.lists.filter(item =>{
                    return item.personCode == personCode
                }) 
   
                
                if(target.length > 0){
                    const indexNum = result.lists.indexOf(target[0])
                    this.selectedTrNum = indexNum+1
                }
            })
        }else if(!this.showSearch){
            api.survey.getSurveyAnswerLists(surveyId).then(answerListResult => {
                //init  answers
                this.completionLists = []
                this.displayCompletionLists = []

                answerListResult.forEach(answer => {
                    //중복답변인지 확인한다.
                    // answer.isMultipleAnswer
                    //store 에 push 할 것인지 지우고 푸가 할 것인지 결정하기 위함
                    var answerCount = answerListResult.filter(item => {
                        return item.surveyQuestionId == answer.surveyQuestionId
                    })
    
                    if (answerCount.length > 1) {
                        answer.isMultipleAnswer = true
                    }
    
                    this.initAnswer(answer)
                })
            
                //this.answers = answerListResult
     
    
            })
        }

      
    }

     

    convertDate(date: string) {
        return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd H:i:s", new Date(date))
    }

    

    onSelectCompletion(index : number, personCode : string){
        this.selectedTrNum = index
        const surveyId = <number>this.surveyDetail.surveyDetailInfo.surveyId

        api.survey.getSurveyAnswerLists(surveyId,personCode).then(answerListResult => {
            //init  answers
 
            answerListResult.forEach(answer => {
                //중복답변인지 확인한다.
                // answer.isMultipleAnswer
                //store 에 push 할 것인지 지우고 푸가 할 것인지 결정하기 위함
                var answerCount = answerListResult.filter(item => {
                    return item.surveyQuestionId == answer.surveyQuestionId
                })

                if (answerCount.length > 1) {
                    answer.isMultipleAnswer = true
                }

                this.initAnswer(answer)
            })
        
            //this.answers = answerListResult
 

        })
    }

    onChangedSearch(keyword : string){
        
        //검색 할 때 선택된 로우 해제
        this.selectedTrNum = 0

        let parseKeyword = keyword.toLowerCase() // 대소문자 구분 X

        if(keyword == ""){
            this.displayCompletionLists = this.completionLists.map(item =>{
                return {
                    ...item,
                    displayCreatedDate: this.convertDate(item.createdDate.toString())
                }
            })
        }else{
            this.displayCompletionLists = this.completionLists.filter(item =>{
                return item.displayName.toLowerCase().includes(parseKeyword) || 
                       item.deptName.toLowerCase().includes(parseKeyword) ||
                       item.titleName.toLowerCase().includes(parseKeyword)
            }).map(item =>{
                return {
                    ...item,
                    displayCreatedDate: this.convertDate(item.createdDate.toString())
                }
            })
        }
    }

    isWriter(){


        if(this.surveyDetail.surveyDetailInfo.createdId.toLowerCase() == this.user.user.personCode.toLocaleLowerCase()){
            return true
        }

        return false
    }
  
}
